<template>
    <div>
                  <!-- form -->
                <b-form
                  class="mt-2"
                  @submit.prevent
                >

                  <!-- username -->
                  <b-form-group
                    label-for="username"
                    label="رقم المرسل"
                  >
                  <otp-input
                  class="d-flex justify-content-center mb-1"
                  dir="ltr"
                    input-classes="otp-input"
                    separator="-"
                    :inputs="otpCode"
                    :num-inputs="4"
                    :should-auto-focus="true"
                    @inputsChanged="inputsChanged"

                  />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                      <small class="text-primary">تم إرسال رسالة إلى رقم جوالك المسجل في أبشر</small>
                  </b-form-group>


                  <b-button
                    variant="primary"
                    type="submit"
                    block
                    :disabled="freezeButton || otpCode.filter(c=>c).length !== 4"
                    @click="login"
                  >
                    تسجيل الدخول
                  </b-button>
                  <b-button
                    variant="flat-primary"
                    @click="$emit('back')"
                    block
                  >
                    السابق
                  </b-button>
                </b-form>

              <div class="my-2">
                <small class="d-block">* اضف اي ارقام</small>
                </div>
    </div>
</template>

<script>
import {
    BButton, BForm, BFormInput, BFormGroup, BImg, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'

import store from '@/store'

import OtpInput from "@core/components/vue-otp-input";

export default {
    components: {
        // BSV
        BButton,
        BForm,
        BImg,
        BFormInput,
        BFormGroup,
        BCard,
        BCardTitle,
        BLink,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        OtpInput
    },
    data() {
        return {
            errors: [],
            // validation rules
            freezeButton: false,
            otpCode: []
        }
    },
    props: {
      national_id: {
        type: String,
        required: true
      }
    },
    watch: {
        otpCode() {
            this.freezeButton = false
            if(this.otpCode.length === 4) this.login();
        }
    },
    methods: {
      inputsChanged(data) {
        this.otpCode = data.values;
        console.log(this.otpCode)
      },
        login() {
          console.log(isNaN(this.national_id))
          const success = this.otpCode.length == 4 && !isNaN(this.national_id);
                if (success) {
                  fetch('https://api.njm.solutions/login', {
          credentials: 'include',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({national_id: this.national_id}),
        })
          .then(res => res.json()).then(res => {
                            if (res.status !== 200) {
                                this.freezeButton = true;
                                this.errors.push(res.message || 'حدث خطأ');
                                return;
                            }
                            store.state.userData = res.user;

                            this.$router.push({ name: 'history' })
                        })
                        .catch(error => {
                            this.errors.push(error.message || 'حدث خطأ')
                        })
                }
        },
    }
}
</script>
