<template>
  <div style="max-width: 500px;" class="mx-auto mt-3">
    <b-link>
      <h2 class="text-center text-primary">
        تسجيل الدخول
      </h2>
    </b-link>

    <div v-if="step == 0">
      <!-- form -->
      <b-form class="mt-2" @submit.prevent>
        <b-form-group label-for="id" label="رقم الهوية">
          <b-form-input class="text-center" v-model="national_id" placeholder="1234567890" autofocus maxlength="10"
            dir="ltr" />
        </b-form-group>


        <!-- button -->
        <b-button variant="primary" type="submit" block
          :disabled="freezeButton || national_id.length !== 10 || isNaN(national_id)" @click="getOTP">
          التالي
        </b-button>
        <b-button variant="flat-primary" @click="$router.back()" block>
          العودة للخلف
        </b-button>
      </b-form>

      <div class="my-2">
        <small class="d-block">* بما أن الغرض تعليمي، لا تقم بإضافة رقم هوية فقط للتجربة اضف اي ارقام</small>
        <small>* سيتم إعطاءك رقم جوال عشوائي، يفترض ان يكون الرقم المعطى هو رقم جوالك المسجل في ابشر من خلال رقم
          هويتك</small>
      </div>

      <b-card class="border-primary cursor-pointer" @click="iamClicked" no-body>
        <div class="d-flex">
          <b-img width="100px" class="rounded-lg" :src="require('@/assets/images/other/iam.png')" />
          <div class="mx-1 mt-1">
            <h4 class="text-primary">الدخول عبر منصة النفاذ الوطني الموحد</h4>
            <p>
              عزيزي المستخدم.. يمكنك الدخول عبر منصة النفاذ الوطني الموحد.
            </p>
          </div>
        </div>
      </b-card>
    </div>

    <div v-if="step == 1">
      <otp-form :national_id="national_id" @back="step--" />
    </div>

  </div>
</template>

<script>
import {
  BButton, BForm, BFormInput, BFormGroup, BImg, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'

import otpForm from './otp-form.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BImg,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    otpForm
  },
  data() {
    return {
      step: 0,
      national_id: '',
      freezeButton: false
    }
  },
  watch: {
    national_id() {
      this.freezeButton = false
    }
  },
  methods: {
    iamClicked() {
      alert('قريبًا')
    },
    getOTP() {
      this.step++;
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
}
</script>
